$(document).on('click', '#event-calendar .cal-direction', function (e) {
    e.preventDefault();

    $.ajax({
        url: '/api/calendar',
        type: 'POST',
        data: { base_date: $(this).data('newdate'), lang_id: document.documentElement.lang }
    }).done(function (result) {
        $('#event-calendar').html(result);
    });
});
