var forEach = function(array, callback, scope) {
    for (var i = 0; i < array.length; i++) {
        callback.call(scope, i, array[i]);
    }
};

var showElem = function(elem) {
    if (elem) {
        elem.style.display = 'block';
    }
};

var showByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function(index, elem) {
            elem.style.display = 'block';
        });
    }
};

var hideElem = function(elem) {
    elem.style.display = 'none';
};

var hideByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function(index, elem) {
            elem.style.display = 'none';
        });
    }
};

var toggleElem = function(elem) {
    if (elem.style.display == 'block') {
        elem.style.display = 'none';
    } else {
        elem.style.display = 'block';
    }
};

var toggleByClass = function(cl) {
    var elems = document.getElementsByClassName(cl);

    if (elems.length) {
        forEach(elems, function(index, elem) {
            if (elem.style.display == 'block') {
                elem.style.display = 'none';
            } else {
                elem.style.display = 'block';
            }
        });
    }
};

var hasClass = function(elem, cl) {
    return new RegExp(' ' + cl + ' ').test(' ' + elem.className + ' ');
};

var addClass = function(selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;

        if (!hasClass(elem, cl)) {
            elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function(index, elem) {
                if (!hasClass(elem, cl)) {
                    elem.className == '' ? elem.className = cl : elem.className += ' ' + cl;
                }
            });
        }
    }

    return null;
};

var removeClass = function(selector, cl) {
    if (typeof selector === 'object') {
        var elem = selector;
        var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

        if (hasClass(elem, cl)) {
            while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                newcl = newcl.replace(' ' + cl + ' ', ' ');
            }
            elem.className = newcl.replace(/^\s+|\s+$/g, '');
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function(index, elem) {
                var newcl = ' ' + elem.className.replace(/[\t\r\n]/g, ' ') + ' ';

                if (hasClass(elem, cl)) {
                    while (newcl.indexOf(' ' + cl + ' ') >= 0) {
                        newcl = newcl.replace(' ' + cl + ' ', ' ');
                    }
                    elem.className = newcl.replace(/^\s+|\s+$/g, '');
                }
            });
        }
    }

    return null;
};

var toggleClass = function(selector, cl) {
    if (typeof selector === 'object') {
        if (selector.length > 0) {
            forEach(selector, function(index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        } else {
            hasClass(selector, cl) ? removeClass(selector, cl) : addClass(selector, cl);
        }
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function(index, elem) {
                hasClass(elem, cl) ? removeClass(elem, cl) : addClass(elem, cl);
            });
        }
    }

    return null;
};

var addContent = function(selector, content) {
    if (typeof selector === 'object') {
        var elem = selector;

        elem.innerHTML = content;
    }

    if (typeof selector === 'string') {
        var elems = document.querySelectorAll(selector);

        if (elems.length) {
            forEach(elems, function(index, elem) {
                elem.innerHTML = content;
            });
        }
    }

    return null;
};

var animate = function(elem, style, unit, from, to, time, prop) {
    var start = new Date().getTime(),
        timer = setInterval(function() {
            var step = Math.min(1, (new Date().getTime() - start) / time);
            if (prop) {
                elem[style] = (from + step * (to - from)) + unit;
            } else {
                elem.style[style] = (from + step * (to - from)) + unit;
            }
            if (step == 1) clearInterval(timer);
        }, 25);

    elem.style[style] = from + unit;
};

var scrollToElem = function(elem) {
    if (typeof selector == 'object') {
        var elem = selector;
    }

    if (typeof selector == 'string') {
        var elem = document.querySelector(selector);
    }

    window.onload = function() {
        animate(document.body, 'scrollTop', '', 0, elem.offsetTop * 2.5, 250, true);
    }
};

var wrapElements = function(top, selector, bottom) {
    var matches = document.querySelectorAll(selector);
    for (var i = 0; i < matches.length; i++) {
        var modified = top + matches[i].outerHTML + bottom;
        matches[i].outerHTML = modified;
    }
};

var isValid = function (email) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    return pattern.test(email);
};

var formatNumber = function (number, decimals, dec_point, thousands_sep) {
    var n = number, c = isNaN(decimals = Math.abs(decimals)) ? 2 : decimals;
    var d = dec_point == undefined ? "," : dec_point;
    var t = thousands_sep == undefined ? "." : thousands_sep, s = n < 0 ? "-" : "";
    var i = parseInt(n = Math.abs(+n || 0).toFixed(c)) + "", j = (j = i.length) > 3 ? j % 3 : 0;
    return s + (j ? i.substr(0, j) + t : "") + i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + t) + (c ? d + Math.abs(n - i).toFixed(c).slice(2) : "");
};

var getAjax = function (url, success) {
    var xhr = window.XMLHttpRequest ? new XMLHttpRequest() : new ActiveXObject('Microsoft.XMLHTTP');
    xhr.open('GET', url);
    xhr.onreadystatechange = function () {
        if (xhr.readyState > 3 && xhr.status == 200) success(xhr.responseText);
    };
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.send();
    return xhr;
};

var postAjax = function (url, data, success) {
    var params = typeof data == 'string' ? data : Object.keys(data).map(
        function (k) {
            return encodeURIComponent(k) + '=' + encodeURIComponent(data[k])
        }
    ).join('&');

    var xhr = new XMLHttpRequest();

    xhr.open('POST', url, true);
    xhr.setRequestHeader('X-Requested-With', 'XMLHttpRequest');
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
    xhr.onreadystatechange = function () {
        if (xhr.readyState > 3 && xhr.status == 200) {
            success(xhr.responseText);
        }
    };
    xhr.send(params);

    return xhr;
};

var serializeArray = function (form) {
    var field, s = [];

    if (typeof form == 'object' && form.nodeName == 'FORM') {
        for (var i = 0; i < form.elements.length; i++) {
            field = form.elements[i];
            if (field.name && !field.disabled && field.type != 'file' && field.type != 'reset' && field.type != 'submit' && field.type != 'button') {
                if (field.type == 'select-multiple') {
                    for (var j = 0; j < form.elements[i].options.length; j++) {
                        if (field.options[j].selected) {
                            s[s.length] = { name: field.name, value: field.options[j].value };
                        }
                    }
                } else if ((field.type != 'checkbox' && field.type != 'radio') || field.checked) {
                    s[s.length] = { name: field.name, value: field.value };
                }
            }
        }
    }

    return s;
};

function closest(el, selector) {
  const matchesSelector = el.matches || el.webkitMatchesSelector || el.mozMatchesSelector || el.msMatchesSelector;

  while (el) {
    if (matchesSelector.call(el, selector)) {
      return el;
    } else {
      el = el.parentElement;
    }
  }
  return null;
}
