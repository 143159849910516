var siteWrapper = document.querySelector('.site-wrapper');
var heroCarousel = document.querySelector('.herospace .wrapper');
var menuToggler = document.querySelector('.nav-menu-toggler');
var menuItem = document.querySelector('.left-nav');
var datePickers = document.querySelectorAll('.date-input');
var masonryGrid = document.querySelector('.masonry-grid');
var eventFilters = document.querySelector('.event-filters');
var contactMap = document.getElementById('contact-map');
var scrollAlert = document.querySelector('.alert');
var sightlessBtns = document.querySelectorAll('.sightless-block .sl-size');
var profileLoginPopup = document.querySelectorAll('.login-popup-wrapper');
var profileMenuButton = document.querySelectorAll('.user-login.logged-in');
var mentorModulModal = document.querySelectorAll('.mentor-modul-modal-trigger');
var profileFormTabs = document.querySelectorAll('.form-tabs');
var regEntityChoiceAnimation = document.querySelectorAll('.choice-tiles');
var adSearch = document.querySelectorAll('.advert-search');
var customDropdown = document.querySelectorAll('.custom-dropdown');
var ratingBubble = document.querySelectorAll('.rating-bubble');
var infotarDocList = document.querySelectorAll('.infotar-item-list');
var newPorTime = document.querySelectorAll('.new-por-time');
var daysSelection = document.querySelectorAll('.days-selection');
var newAdvertFormTabs = document.querySelectorAll('.adverts-new-tabs');
var newAdvertTemplateSelector = document.querySelectorAll('.template-selector');
var newPorPopup = document.querySelectorAll('.new-por-popup-wrapper');
var newContactPopup = document.querySelectorAll('.new-contact-popup-wrapper');
var marketProductTab = document.querySelectorAll('.more-info-tabs');
var ratingPopup = document.querySelectorAll('.rating-popup-wrapper');
var reportingPopup = document.querySelectorAll('.reporting-popup-wrapper, .report-advert-trigger');
var selfEvaluationPopup = document.querySelectorAll('.self-evaluation-popup-wrapper');
var mentorNewTaskMenu = document.querySelectorAll('.mentor-menu-bubble');
var mentorReadMore = document.querySelectorAll('.description-expandable');
var mentorLogHiddenEvents = document.querySelectorAll('.mentor-events-wrapper');
var mentorRating = document.querySelectorAll('.mentor-rating');
var mentorMobileSidebar = document.querySelectorAll('.mentor-wrapper');
var marketMobileFilter = document.querySelectorAll('.market-not-column-reverse');
var selfEvalTextarea = document.querySelectorAll('.q-input-group .custom-textarea');
var addableInputGroup = document.querySelectorAll('.multiline-input-group.addable');
var selfEvalDropdown = document.querySelectorAll('.self-eval-dropdown');
var selfEvalFilePopup = document.querySelector('.file-upload-popup-wrapper');
var selfEvalEditFilePopup = document.querySelector('.file-edit-popup-wrapper');
var referenceInput = document.querySelectorAll('.reference-input');

document.addEventListener('DOMContentLoaded', function () {

    // ONERTEKELES TEXTAREA LENGTH
    if (selfEvalTextarea.length) {
        forEach(selfEvalTextarea, function (index, elem) {
            var maxLength = elem.maxLength;
            var charCounterWrapper = elem.nextElementSibling;
            var currentCount = charCounterWrapper.querySelector('.current-count');
            var maxCount = charCounterWrapper.querySelector('.max-count');
            maxCount.textContent = maxLength;
            currentCount.textContent = elem.value.length;
            elem.addEventListener('keyup', function() {
                var currentLength = elem.value.length;
                currentCount.textContent = currentLength;
                if (currentLength >= maxLength) {
                    return false;
                }
            });
        });
    }

    // ONERTEKELES ADD INPUT LINE
    if (addableInputGroup.length) {
        forEach(addableInputGroup, function (index, elem) {
            var newLineTrigger = elem.querySelector('.add-new-line');
            newLineTrigger.addEventListener('click', function() {
                var inputLines = elem.querySelectorAll('.line:not(.header)');
                var inputLine  = inputLines[inputLines.length - 1];
                var newLine    = inputLine.cloneNode(true);

                var inputs = newLine.querySelectorAll('input, textarea, select');
                inputs.forEach(function(item) {
                    item.value = '';

                    // Date picker hozzaadasa
                    if (item.classList.contains('date-input')) {
                        addDatePicker(item);
                    }

                    // Trumbowyg wysiswy hozzadasa
                    // Regi trumbowyg torlese a textarea megtartasaval.
                    // Majd ujboli initializalsaval.
                    if (item.classList.contains('trumbowyg')) {

                        // Regi ertek torlese
                        item.value = '';

                        // Trumbowyg torlese es textarea atmasolasa
                        var container = item.parentNode.parentNode;
                        container.innerHTML = '';
                        container.insertBefore(item, null);

                        // Init
                        addTrumbowyg($(item));
                    }
                });


                elem.insertBefore(newLine, newLineTrigger);
            });
        });
    }

    if (referenceInput.length) {
        forEach(referenceInput, function (index, elem) {
            var newLineTrigger = elem.parentNode.querySelector('.add-new-line');
            if (newLineTrigger) {
                newLineTrigger.addEventListener('click', function () {
                    var inputLines = elem.querySelectorAll('.reference-input-line');
                    var inputLine  = inputLines[inputLines.length - 1];
                    var newInput   = inputLine.cloneNode(true);

                    var inputs = newInput.querySelectorAll('input, textarea, select');
                    inputs.forEach(function(item) {
                        item.value = '';

                        // Date picker hozzaadasa
                        if (item.classList.contains('date-input')) {
                            addDatePicker(item);
                        }

                        // Trumbowyg wysiswy hozzadasa
                        // Regi trumbowyg torlese a textarea megtartasaval.
                        // Majd ujboli initializalsaval.
                        if (item.classList.contains('trumbowyg')) {

                            // Regi ertek torlese
                            item.value = '';

                            // Trumbowyg torlese es textarea atmasolasa
                            var container = item.parentNode.parentNode;
                            container.innerHTML = '';
                            container.insertBefore(item, null);

                            // Init
                            addTrumbowyg($(item));
                        }
                    });

                    inputLine.parentNode.insertBefore(newInput, inputLines.nextSibling);
                });
            }
        });
    }

    // ONERTEKELES FILE UPLOAD POPUP
    if (selfEvalFilePopup) {
        var popupTrigger = document.querySelectorAll('.file-upload-trigger');
        // OPEN
        forEach(popupTrigger, function (index, elem) {
            elem.addEventListener('click', function() {

                // A kerese id-jat taroljuk le, hogy form kuldeskor ezt is el tudjuk kuldeni
                var questionId = elem.dataset.questionId ? elem.dataset.questionId : null;

                selfEvalFilePopup.classList.add('active');
                selfEvalFilePopup.querySelector('[name="file[question_id]"]').value = questionId;
            });
        });
        // CLOSE
        var closeTrigger = document.querySelector('.file-upload-popup-wrapper .flaticon-cancel');
        closeTrigger.addEventListener('click', function() {
            selfEvalFilePopup.classList.remove('active');
        });
    }

    // ONERTEKELES FILE EDIT POPUP
    if (selfEvalEditFilePopup) {
        //var popupTrigger = document.querySelectorAll('.file-edit-trigger');
        // OPEN

        //forEach(popupTrigger, function (index, elem) {
        //    elem.addEventListener('click', function() {
        //        selfEvalEditFilePopup.classList.add('active');
        //    });
        //});
        // CLOSE
        var closeTrigger = document.querySelector('.file-edit-popup-wrapper .flaticon-cancel');
        closeTrigger.addEventListener('click', function() {
            selfEvalEditFilePopup.classList.remove('active');
        });
    }

    // Herospace carousel
    if (heroCarousel) {
        var flktyInst = new Flickity(heroCarousel, {
            autoPlay: 5000,
            cellAlign: 'center',
            resize: false,
            pageDots: true,
            prevNextButtons: false,
            wrapAround: true
        });
    }

    // Displaying mobile menu
    if (menuToggler) {
        menuToggler.addEventListener('click', function (e) {
            e.preventDefault();

            if (menuItem) {
                if (hasClass(this, 'open')) {
                    removeClass(this, 'open');
                    menuItem.style.left = '-220px';
                    siteWrapper.style.left = '0';
                } else {
                    addClass(this, 'open');
                    menuItem.style.left = '0';
                    siteWrapper.style.left = '220px';
                }
            }
        });
    }

    var addDatePicker = function(elem) {
        var opts = { field: elem, defaultDate: elem.value };

        if (document.documentElement.lang == 'hu') {
            opts.firstDay = 1;
            opts.format = 'YYYY-MM-DD';
            opts.i18n = {
                previousMonth: 'Előző hónap',
                nextMonth: 'Következő hónap',
                months: ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'],
                weekdays: ['Vasárnap', 'Hétfő', 'Kedd', 'Szerda', 'Csütörtök', 'Péntek', 'Szombat'],
                weekdaysShort: ['Vas', 'H', 'K', 'Sze', 'Cs', 'P', 'Szo']
            }
        } else {
            opts.firstDay = 0;
            opts.format = 'YYYY-MM-DD';
        }

        var picker = new Pikaday(opts);
    };

    // Date pickers
    if (datePickers) {
        forEach(datePickers, function (index, elem) {
            addDatePicker(elem);
        });
    }

    // Filtering event results
    if (eventFilters) {
        forEach(eventFilters.querySelectorAll('[data-listen]'), function (index, elem) {
            elem.addEventListener('change', function () {
                eventFilters.submit();
            });
        });
    }

    // Alerts
    if (scrollAlert) {
        $('html, body').animate({ scrollTop: $('.alert').offset().top }, 500);
    }

    // Sightless font sizes
    if (sightlessBtns) {
        forEach(sightlessBtns, function (index, elem) {
            elem.addEventListener('click', function () {
                if (hasClass(elem, 'sl-size-plus')) {
                    removeClass('.site-wrapper', 'fonts-smaller');
                    addClass('.site-wrapper', 'fonts-bigger');
                }

                if (hasClass(elem, 'sl-size-minus')) {
                    removeClass('.site-wrapper', 'fonts-bigger');
                    addClass('.site-wrapper', 'fonts-smaller');
                }
            });
        });
    }

    // Footer Google Maps
    if (contactMap) {
        var lat = gMap.dataset.lat;
        var lng = gMap.dataset.lng;
        var mapOptions = {
            zoom: 12,
            center: new google.maps.LatLng(lat, lng),
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            disableDefaultUI: true,
            scrollwheel: false
        };

        var map = new google.maps.Map(gMap, mapOptions);
        var marker = new google.maps.Marker({ position: new google.maps.LatLng(lat, lng), map: map });
    }

    // PROFILE PAGE LOGIN POPUP
    if (profileLoginPopup) {
        // OPEN
        $('.user-login').on('click', function() {
            if ( !$(this).hasClass('logged-in') ) {
                $('.login-popup-wrapper').addClass('active');
            }
        });
        // CLOSE
        $('.login-popup .fi-close').on('click', function() {
            $('.login-popup-wrapper').removeClass('active');
        });
        $('.login-flex').on('click', function() {
            $('.login-popup-wrapper').removeClass('active');
        });
        $('.login-popup').on('click', function(e) {
            e.stopPropagation();
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.login-popup-wrapper').hasClass('active')) {
                $('.login-popup-wrapper').removeClass('active');
            }
        });
    }

    // MENTOR MODUL MODAL
    if (mentorModulModal) {
        // OPEN
        $('.mentor-modul-modal-trigger').on('click', function(e) {
            e.preventDefault();
            $('.mentor-modul-modal').addClass('active');
        });
        // CLOSE
        $('.modal-close').on('click', function() {
            $('.mentor-modul-modal').removeClass('active');
        });
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.login-popup-wrapper').hasClass('active')) {
                $('.login-popup-wrapper').removeClass('active');
            }
        });
    }

    // PROFILE PAGE MENU
    if (profileMenuButton) {
        $('.btn-logged-in').on('click', function() {
            if ($(this).hasClass('open')) {
                // CLOSE
                $(this).removeClass('open');
                $(this).next().addClass('fadeOut').removeClass('active animated fadeIn fadeOut');
            } else {
                // OPEN
                $(this).addClass('open');
                $(this).next().addClass('active animated fadeIn');
            }
        });
    }

    //INFOTAR DOCLIST
    if (infotarDocList) {
        $('.list-open-trigger').on('click', function() {
            var triggeredDocHeader = $(this).closest('.infotar-item').find('.item-header');
            var triggeredDocList = $(this).closest('.infotar-item').find('.doc-list');
            if ($(this).hasClass('open')) {
                // CLOSE
                $(this).removeClass('open');
                triggeredDocHeader.removeClass('active');
                triggeredDocList.addClass('fadeOut').removeClass('active animated fadeIn fadeOut');
            } else {
                // OPEN
                $(this).addClass('open');
                triggeredDocHeader.addClass('active');
                triggeredDocList.addClass('active animated fadeIn');
            }
        });
    }

    // PROFILE PAGE FORM TABS
    if (profileFormTabs) {
        $('.form-tab').on('click', function() {
            var formTabTarget = $(this).attr('data-target');
            // clear all active classes
            $('.form-tab').each(function() {
                $(this).removeClass('active');
            });
            $('.input-group').each(function() {
                $(this).removeClass('active');
            });
            // add 'active' class on relevant elements
            $(this).addClass('active');
            $('#' + formTabTarget).addClass('active');
        });
    }

    // REGISTRATION ENTITY CHOICE ANIMATIONS
    if (regEntityChoiceAnimation) {

        var $tileWrapper = $('.choice-tiles');
        var modifierClasses = ["top-left-expanded", "bottom-left-expanded", "top-right-expanded", "bottom-right-expanded"];

        var resizeAnimation = function() {

            if (window.matchMedia('(min-width: 769px)').matches) {

                $('.choice-tile').on('mouseenter', function() {

                // top left
                if ($(this).hasClass('choice-tile--top-left')) {
                    $.each(modifierClasses, function(i, v){
                       $tileWrapper.removeClass(v);
                    });
                    $tileWrapper.addClass('top-left-expanded');
                }

                // bottom left
                if ($(this).hasClass('choice-tile--bottom-left')) {
                    $.each(modifierClasses, function(i, v){
                       $tileWrapper.removeClass(v);
                    });
                    $tileWrapper.addClass('bottom-left-expanded');
                }

                // top right
                if ($(this).hasClass('choice-tile--top-right')) {
                    $.each(modifierClasses, function(i, v){
                       $tileWrapper.removeClass(v);
                    });
                    $tileWrapper.addClass('top-right-expanded');
                }

                // bottom right
                if ($(this).hasClass('choice-tile--bottom-right')) {
                    $.each(modifierClasses, function(i, v){
                       $tileWrapper.removeClass(v);
                    });
                    $tileWrapper.addClass('bottom-right-expanded');
                }

                });

                $($tileWrapper).on('mouseleave', function(){
                    $.each(modifierClasses, function(i, v){
                       $tileWrapper.removeClass(v);
                    });
                });

            } else {
                // making sure its not buggy if kind testers resize the browser window :)
                $('.choice-tile').off();
                $($tileWrapper).off();
            }
        };

        $(window).on('resize load', function() {
            resizeAnimation();
        });
    }

    // Masonry grid
    if (masonryGrid) {
        var msnry;

        imagesLoaded(masonryGrid, function () {
            msnry = new Masonry(masonryGrid, {
                itemSelector: '.masonry-item',
                columnWidth: '.grid-sizer',
                gutter: '.gutter-sizer',
                percentPosition: true
            });
        });
    }

    // search on adverts page
    if (adSearch) {

        $('.btn-ad-search').on('click', function(e) {

            var $searchWrapper = $('.search-wrapper');

            // Ha eloszor kattintunk, ra akkor ne engedje elkuldeni a keresest
            if (!$searchWrapper.hasClass('open')) {
                e.preventDefault();
                $searchWrapper.addClass('open');
            }
        });
    }

    // custom dropdown on advert page
    if (customDropdown) {

        $('.custom-dropdown').on('click', function() {

            if ($(this).hasClass('active')) {
                // closing dropdown on clicking on the dropdown header-bar
                $(this).removeClass('active');
            } else {
                $('.custom-dropdown').each(function() {
                    $(this).removeClass('active');
                });
                $(this).toggleClass('active');
                return false;
            }
        });

        // putting default value into placeholder
        $('.custom-dropdown').each(function() {
            var dataStatus = $(this).data('status');
            var placeholder = $(this).find('.placeholder');
            var defaultItem = $('.dropdown-item-' + dataStatus).html();

            placeholder.html(defaultItem);

        });

        // putting selected value into placeholder
        $('.dropdown-item').on('click', function() {
            var val = $(this).html();
            var parentPlaceholder = $(this).parent().siblings('.placeholder');
            var parentDropdown = $(this).closest('.custom-dropdown');

            parentPlaceholder.html(val);

            // and closing parent dropdown

            setTimeout(function(){
                parentDropdown.removeClass('active');
            }, 50);
        });

        // closing dropdown on outer click
        $(document).click(function() {
            $('.custom-dropdown').each(function() {
                $(this).removeClass('active');
            });
        });

        $('.dropdown .btn-trigger').on('click', function() {
            if ($(this).hasClass('open')) {
                // CLOSE
                $(this).removeClass('open');
                $(this).next().addClass('fadeOut').removeClass('active animated fadeIn fadeOut');
            } else {
                // OPEN
                $(this).addClass('open');
                $(this).next().addClass('active animated fadeIn');
            }
        });

    }

    // adverts page rating bubble toggler
    if (ratingBubble) {
        // open
        $('.rating-message-trigger').on('click', function() {
            var bubble = $(this).siblings('.rating-bubble');

            //hiding other bubbles on trigger
            $('.rating-bubble').each(function() {
                $(this).removeClass('active');
            });

            bubble.addClass('active');
        });
        // close
        $('.rating-header > .close-icon').on('click', function(){
            $('.rating-bubble').each(function() {
                $(this).removeClass('active');
            });
        });
        $(document).on('click', function(event) {
          if (!$(event.target).parents().addBack().is('.rating-message-trigger')) {
            $('.rating-bubble').removeClass('active');
          }
        });
        $('.rating-bubble').on('click', function(event) {
          event.stopPropagation();
        });
    }

    // new POR time
    if (newPorTime) {
        // OPEN + clear inputs and days
        $('.btn-new-por-time').on('click', function() {
            $('.new-por-time').addClass('active');
            $('.form-input').each(function() {
                $(this).val('');
            });
            $('.days-selection > .day').each(function() {
                $(this).removeClass('active');
            });
        });
        // CLOSE
        $('.btn-new-por-time-save-cancel').on('click', function() {
            $('.new-por-time').removeClass('active');
        });
    }

    // days selection
    if (daysSelection) {
        $('.days-selection > .day').on('click', function() {
            $(this).toggleClass('active');
        });
    }

    //NEW ADVERT TABS
    if (newAdvertFormTabs) {
        $('.adverts-new-tab, .btn-new-advert-details').on('click', function() {
            var formTabTarget = $(this).attr('data-target');
            // clear all active classes
            $('.adverts-new-tab').each(function() {
                $(this).removeClass('active');
            });
            $('.input-group').each(function() {
                $(this).removeClass('active');
            });
            // add 'active' class on relevant tab
            if ( $(this).hasClass('btn-new-advert-details') ) {
                $('.adverts-new-tab[data-target="new-advert-detailed"]').addClass('active');
            } else {
                $(this).addClass('active');
            }
            $('#' + formTabTarget).addClass('active');
        });
    }

    //NEW ADVERT TEMPLATE SELECTOR
    if(newAdvertTemplateSelector) {
        // select template initially
        $('.template-tile').on('click', function(){
            var templateTarget = $(this).attr('data-target');
            $('#' + templateTarget).addClass('active');
            $('.template-selector').addClass('hidden');
        });
        // select other template link
        $('.template-select-other').on('click', function() {
            $('.template-selector').removeClass('hidden');
            $('.template-wrapper').removeClass('template-1 template-2 template-3');
        });
    }

    // ADVERT NEW/EDIT NEW POR POPUP
    if (newPorPopup) {
        // OPEN
        $('.btn-new-por-small').on('click', function() {
            $('.new-por-popup-wrapper').addClass('active');
        });
        // CLOSE
        $('.new-por-popup .fi-close').on('click', function() {
            $('.new-por-popup-wrapper').removeClass('active');
        });
        $('.new-por-flex').on('click', function() {
            $('.new-por-popup-wrapper').removeClass('active');
        });
        $('.new-por-popup').on('click', function(e) {
            e.stopPropagation();
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.new-por-popup-wrapper').hasClass('active')) {
                $('.new-por-popup-wrapper').removeClass('active');
            }
        });
    }

    // ADVERT NEW/EDIT NEW CONTACT POPUP
    if (newContactPopup) {
        // OPEN
        $('.btn-new-contact-small').on('click', function() {
            $('.new-contact-popup-wrapper').addClass('active');
        });
        // CLOSE
        $('.new-contact-popup .fi-close').on('click', function() {
            $('.new-contact-popup-wrapper').removeClass('active');
        });
        $('.new-contact-flex').on('click', function() {
            $('.new-contact-popup-wrapper').removeClass('active');
        });
        $('.new-contact-popup').on('click', function(e) {
            e.stopPropagation();
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.new-contact-popup-wrapper').hasClass('active')) {
                $('.new-contact-popup-wrapper').removeClass('active');
            }
        });
    }

    // MARKET PRODUCT TABS
    if (marketProductTab) {
        /*
        // setting fix height, so changing tabs wont make the site bouncing
        var descHeight = $('#description').height();
        var ratingsHeight = $('#ratings').height();
        var contactHeight = $('#contact').height();
        var maxHeight = Math.max(descHeight, ratingsHeight, contactHeight);
        $('.tab-content').each(function() {
            $(this).height(maxHeight);
        });
        // also on resize
        $( window ).resize(function() {
            var descHeight = $('#description').height();
            var ratingsHeight = $('#ratings').height();
            var contactHeight = $('#contact').height();
            var maxHeight = Math.max(descHeight, ratingsHeight, contactHeight);
            $('.tab-content').each(function() {
                $(this).height(maxHeight);
            });
        });
        */
        // tab content
        $('.tab').on('click', function() {
            var formTabTarget = $(this).attr('data-target');

            if (formTabTarget) {
                // clear all active classes
                $('.tab').each(function() {
                    $(this).removeClass('active');
                });
                $('.tab-content').each(function() {
                    $(this).removeClass('active');
                });
                // add 'active' class on relevant elements
                $(this).addClass('active');
                $('#' + formTabTarget).addClass('active');
            }
        });
    }

    // MARKET RATING POPUP
    if (ratingPopup.length) {



        // OPEN
        $('.btn-rating-trigger').on('click', function() {
            $('.rating-popup-wrapper').addClass('active');
            // reset rating stars
            $('.rating-star').each(function() {
                $(this).removeClass('on clicked');
            });
            // reset rating nr value
            $('.nr-of-stars > .nr-actual').html('0');
        });
        // CLOSE
        $('.rating-popup .fi-close').on('click', function() {
            $('.rating-popup-wrapper').removeClass('active');
        });
        $('.rating-flex').on('click', function() {
            $('.rating-popup-wrapper').removeClass('active');
        });
        $('.rating-popup').on('click', function(e) {
            e.stopPropagation();
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.rating-popup-wrapper').hasClass('active')) {
                $('.rating-popup-wrapper').removeClass('active');
            }
        });
        // STAR FUNCTIONS
        // hover
        $('.rating-star').hover(function(){
            ratingValue = parseInt($(this).data('rating'), 10);
            var i;
            for (i = 1; i <= ratingValue; i++) {
                $('.rating-star[data-rating=' + i + ']').addClass('on');
            }
            }, function(){
            $('.rating-star').each(function() {
                if (!$(this).hasClass('clicked')) {
                    $(this).removeClass('on');
                }
            });
        });

        $('.hidden-radio').on('change', function(e) {
            // console.log(e.target.value);
        });

        // click
        $('.rating-star').on('click', function(){
            // reset on click
            $('.rating-star').each(function() {
                $(this).removeClass('on clicked');
            });
            // setting classes
            ratingValue = parseInt($(this).data('rating'), 10);
            var i;
            for (i = 1; i <= ratingValue; i++) {
                $('.rating-star[data-rating=' + i + ']').addClass('on clicked');
            }
            // storing rating value
            $('.hidden-rating').val(ratingValue).trigger('change');

            // writing value in text
            $('.nr-of-stars > .nr-actual').html(ratingValue);
        });
    }

    // MARKET REPORTING POPUP
    if (reportingPopup.length) {

        // OPEN
        $('.btn-reporting-trigger').on('click', function() {
            var ratingId = $(this).data('rating_id');
            $('#reportingFormRatingId').val(ratingId);
            $('.reporting-popup-wrapper').addClass('active');
        });

        // Ez igy csak azert mukodik, mert az ertekeles jelentese es a hirdetes jelentese
        // nincs egy kozos oldalon. Kulonben duplikalni kellene a kodokat
        $('.report-advert-trigger').on('click', function() {
            $('.reporting-popup-wrapper').addClass('active');
        });


        // CLOSE
        $('.reporting-popup .fi-close').on('click', function() {
            $('.reporting-popup-wrapper').removeClass('active');
        });
        $('.reporting-flex').on('click', function() {
            $('.reporting-popup-wrapper').removeClass('active');
        });
        $('.reporting-popup').on('click', function(e) {
            e.stopPropagation();
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.reporting-popup-wrapper').hasClass('active')) {
                $('.reporting-popup-wrapper').removeClass('active');
            }
        });
    }


    // SELF EVALUATION REJECT POPUP
    if (selfEvaluationPopup) {
        // OPEN
        $('.btn-se-trigger').on('click', function() {
            $('.self-evaluation-popup-wrapper').addClass('active');
        });
        // CLOSE
        $('.se-popup .fi-close').on('click', function() {
            $('.self-evaluation-popup-wrapper').removeClass('active');
        });
        $('.se-flex').on('click', function() {
            $('.self-evaluation-popup-wrapper').removeClass('active');
        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && $('.self-evaluation-popup-wrapper').hasClass('active')) {
                $('.self-evaluation-popup-wrapper').removeClass('active');
            }
        });
    }

    //MENTOR PAGE NEW TASK MENU
    if (mentorNewTaskMenu) {
        var trigger = $('.mentor-menu-trigger');
        var menu = $('.mentor-menu-bubble');
        var menuIndicator = $('.menu-indicator');
        // OPEN
        trigger.on('click', function() {
            menu.toggleClass('active');
            trigger.toggleClass('active');
            if (menuIndicator.hasClass('fa-plus')) {
                menuIndicator.removeClass('fa-plus').addClass('fa-times');
            } else {
                menuIndicator.removeClass('fa-times').addClass('fa-plus');
            };

        });
        // CLOSE on ESC
        $(document).bind('keydown', function(e) {
            if (e.which == 27 && menu.hasClass('active')) {
                menu.removeClass('active');
                trigger.removeClass('active');
            }
        });
    }

    // MENTOR PAGE - READ MORE
    if (mentorReadMore) {
        var readMoreWrapper = $('.description-expandable');
        var readMoreTrigger = $('.description-expandable > .read-more');

        readMoreTrigger.on('click', function() {
            readMoreWrapper.addClass('expanded');
        });
    }

    // MENTOR PAGE - HIDDEN EVENTS
    if (mentorLogHiddenEvents) {
        var hiddenEventTrigger = $('#hidden-event-trigger');
        var hiddenEvents = $('.event-item.hidden');

        hiddenEventTrigger.on('change', function() {

            if (hiddenEventTrigger.is(':checked')) {
                hiddenEvents.each(function (){
                    $(this).addClass('active');
                });
            } else {
                hiddenEvents.each(function (){
                    $(this).removeClass('active');
                });
            }

        }).change();
    }

    if (mentorRating.length) {
        // STAR FUNCTIONS
        // hover
        $('.rating-star').hover(function(){
            // get actual star's group
            var starGroup = $(this).data('star-group');

            ratingValue = parseInt($(this).data('rating'), 10);
            var i;
            for (i = 1; i <= ratingValue; i++) {
                $('.rating-star[data-rating='+ i +'][data-star-group='+ starGroup +']').addClass('on');
            }
            }, function(){
            $('.rating-star').each(function() {
                if (!$(this).hasClass('clicked')) {
                    $(this).removeClass('on');
                }
            });
        });
        // click
        $('.rating-star').on('click', function(){
            // get actual star's group
            var starGroup = $(this).data('star-group');
            // reset on click
            $('.rating-star[data-star-group='+ starGroup +']').each(function() {
                $(this).removeClass('on clicked');
            });
            // setting classes
            ratingValue = parseInt($(this).data('rating'), 10);
            var i;
            for (i = 1; i <= ratingValue; i++) {
                $('.rating-star[data-rating='+ i +'][data-star-group='+ starGroup +']').addClass('on clicked');
            }
            // resetting radio buttons
            $('.hidden-radio[data-star-group="'+ starGroup +'"]').each(function () {
                $(this).removeAttr('checked');
            });
            // writing value in hidden input
            $('input[data-star-group="'+ starGroup +'"]').val(ratingValue);
            // writing value in text
            $('.nr-of-stars[data-star-group='+ starGroup +'] > .nr-actual').html(ratingValue);
        });
    }

    if (mentorMobileSidebar.length) {

        var sidebar = $('.sidebar');
        var functions = $('.functions');
        var mobileMenuTrigger = $('.mobile-sidebar-trigger');

        mobileMenuTrigger.on('click', function() {
            sidebar.toggleClass('mobile-sidebar-triggered');
            functions.toggleClass('mobile-sidebar-triggered');
        });

    }

    if (marketMobileFilter.length) {

        var filterWrapper = $('.filter-form');
        var filterTriggerOn = $('.filter-form > .btn-filter-trigger-on');
        var filterTriggerOff = $('.filter-form > .btn-filter-trigger-off');

        filterTriggerOn.on('click', function() {
            filterWrapper.addClass('active');
        });

        filterTriggerOff.on('click', function() {
            filterWrapper.removeClass('active');
        });

    }

});

//  Checks that string starts with the specific string
if (typeof String.prototype.startsWith != 'function') {
    String.prototype.startsWith = function (str) {
        return this.slice(0, str.length) == str;
    };
}

//  Checks that string ends with the specific string...
if (typeof String.prototype.endsWith != 'function') {
    String.prototype.endsWith = function (str) {
        return this.slice(-str.length) == str;
    };
}

// lightGallery instances
$(document).on('click', '[data-lg-open]', function (e) {
    e.preventDefault();

    $.ajax({
        url: '/api/get-gallery/' + $(this).data('lg-open'),
        type: 'GET'
    }).done(function(result) {
        $(this).lightGallery({
            thumbnail: true,
            dynamic: true,
            dynamicEl: JSON.parse(result)
        });
    });
});

// Hirdetesek galleria
$(document).on('click', '.gallery .image-view', function (e) {

    // Keresse ki a kicsiben nezegetett kepet, hogy utana nagyban az nyiljon meg
    var currentSrc = $(this).find('img').attr('src');
    var srcs = jQuery.map($(this).next().find('.thumbnail img'), function(item) {
        return item.src;
    });

    //var dynamicEl = jQuery.map....

    var $lg = $(this).lightGallery({
        thumbnail: true,
        index: srcs.indexOf(currentSrc),
        dynamic: true,
        dynamicEl: $(this).next().find('.thumbnail img')
    });

    // Bazaraskor torolje a gallery-t, hogy a kovetkezo megnyitaskor a megfelelo indexu elemet nyissa meg
    // Maskepp nem mukodott!
    $lg.on('onCloseAfter.lg',function(event){
        $lg.data('lightGallery').destroy(true);
    });
});

// registration fields
var registrationFields = {
    hideInvalidDayOptions: function() {
        var
            year = $('.datepicker-container select[name="founded_at_year"] option:selected').val(),
            month = $('.datepicker-container select[name="founded_at_month"] option:selected').val(),
            daySelect = $('.datepicker-container select[name="founded_at_day"]'),
            validDays = Array.apply(null, {length: 32}).map(Number.call, Number);

        validDays = validDays.filter(function (day) {
            return registrationFields.isValidDate(year, month, day);
        });

        daySelect.find('option').each(function () {
            if (validDays.indexOf(parseInt($(this).val())) === -1) {
                $(this).attr('disabled', true);
            } else {
                $(this).attr('disabled', false);
            }
        });
    },

    isValidDate: function(year, month, day) {
        var d = new Date(year, month - 1, day);

        return d.getFullYear() == year && (d.getMonth() + 1) == month && d.getDate() == Number(day);
    },

    showOrganizationFormTextInput: function() {
        var organizationFormSelectContainer = $('.organization-form-container .organization-form-select-container'),
            organizationFormTextContainer = $('.organization-form-container .organization-form-text-container');

        if (organizationFormSelectContainer.find('select option:selected').val() == 'Egyéb, mégpedig...') {
            organizationFormSelectContainer.find('select').attr('disabled', true);
            organizationFormSelectContainer.css('display', 'none');

            organizationFormTextContainer.find('input').attr('disabled', false);
            organizationFormTextContainer.css('display', 'block');
        } else {
            organizationFormSelectContainer.find('select').attr('disabled', false);
            organizationFormSelectContainer.css('display', 'block');

            organizationFormTextContainer.find('input').attr('disabled', true);
            organizationFormTextContainer.css('display', 'none');
        }
    }
};

$(function() {
    registrationFields.hideInvalidDayOptions();
    registrationFields.showOrganizationFormTextInput();
});

$(document).on('click', '.btn.validation.generate-pdf', function() {
    $(document).find('form').submit();
});

$(document).on('change', '#guidance,#cooperation_agreement,#qualification,#organized_event', function() {
    var isNotComplete = false;
    $('#guidance,#cooperation_agreement,#qualification,#organized_event').each(function() {
        if( $(this).find('option:selected').val() != 'yes' && $(this).find('option:selected').val() != 'no' ) {
            isNotComplete = true;
        }
    });

    if(!isNotComplete) {
        $('div.validation-possible').slideDown(function() {
            $('.next-to-step-2 button.btn').removeClass('btn-disabled-gray').removeAttr('disabled');
            $('.btn.validation.generate-pdf').removeClass('btn-disabled-gray').removeAttr('disabled');
        });
        $('.hide.show-only-on-step2').removeClass('hide');
    } else {
        $('div.validation-possible').hide();
        $('.show-only-on-step2').addClass('hide');
        $('.next-to-step-2 button.btn').addClass('btn-disabled-gray').attr('disabled','disabled');
        $('.btn.validation.generate-pdf').addClass('btn-disabled-gray').attr('disabled', 'disabled');
    }
});

$(document).on('click', '.next-to-step-2 button.btn', function(event) {
    event.preventDefault();
    // console.log('click');
    $('html, body').animate({
        scrollTop: $(".output-wrapper").offset().top
    });
});

function trumbowygCounter() {
    // Karakter szamlalo
    if ($(this).data('counter') == true) {

        var min = $(this).data('min') || 100;
        var max = $(this).data('max') || 800;

        $(this).siblings('.trumbowyg-editor').counter({
            goal : max,
            msg: '/ ' + max,
            count: 'up',
            containerClass: 'trumbowyg-counter'
        });
    }
}

function addTrumbowyg($items) {

    $items.trumbowyg({
        svgPath: '/frontend/img/trumbowyg/icons.svg',
        lang: 'hu',
        resetCss: true,
        removeformatPasted: true,
        semantic: false,
        btns: [
            ['bold', 'italic', 'underline', 'strikethrough', 'link'],
            ['unorderedList', 'orderedList'],
            ['formatting'],
            ['removeformat'],
            ['undo', 'redo']
        ]
    }).on('tbwinit', trumbowygCounter);
}

$(document).ready(function() {

    addTrumbowyg($('.trumbowyg'));

    $('.trumbowyg-message').trumbowyg({
        svgPath: '/frontend/img/trumbowyg/icons.svg',
        lang: 'hu',
        resetCss: true,
        removeformatPasted: true,
        semantic: false,
        btns: [
            ['bold', 'italic', 'underline', 'strikethrough', 'link'],
            ['undo', 'redo']
        ]
    }).on('tbwinit', trumbowygCounter);

    // Image picker ie.: intro.blade.php
    $('.image-preview input[type="file"]').change(function(e) {

        var files = e.target.files;

        if (files && files[0]) {
            var reader = new FileReader();
            var $input = $(this);

            reader.onload = function (e) {
                $input.parent().css('background-image', 'url(' + e.target.result + ')')
            };

            reader.readAsDataURL(files[0]);
        }
    });


    // File picker ie.: onertekeles.blade.php
    $('.file-upload').on('change', 'input[type="file"]', function(e) {

        var files = e.target.files;

        if (files && files[0]) {

            var $input    = $(this);
            var uploader  = $input.data('uploader');
            // Eredetileg a mentorterhez lett kitalalva, de az onertekeleshez is kellett
            var deleteUrl = $input.data('delete-url') ? $input.data('delete-url') : '/mentorter/task-files/delete/';
            var uploaded  = $('input[type="file"][data-uploader="'+ uploader +'"]').length - 1;
            var limit     = $input.parent().data('limit');

            // Szoljon, ha elertuk a limitet
            if (limit && uploaded >= limit) {
                alert('Maxium '+limit+' fájlt lehet csatolni!');

                return;
            }

            // A kivalasztott fajlt selectorat masolja at a feltoltott fajlok listajaba
            var $taskFile = $('<div class="list-item"></div>');
            $taskFile.append(
                '<span class="item-text">'+ files[0].name +'</span>',
                '<span class="icon icon-m icon-color-gray-inactive" onClick="deleteFile(this, \'' + deleteUrl + '\')"><i class="fi flaticon-cancel"></i></span>',

                // file input feltoltese
                $input
            );

            $('.list[data-uploader="'+ uploader +'"]').append($taskFile);

            // Egy uj file inputon szurjon be, hogy meg tobb fajlt fel lehessen tolteni
            $('.file-upload[data-uploader="'+ uploader +'"]').prepend('<input type="file" name="files[]" data-uploader="'+ uploader +'" />');
        }
    });

});

function deleteFile(id, url) {

    url = url || '';

    if (confirm('Biztosan tötölni szeretnéd?')) {

        // Feltoltott file torlese
        if ($.isNumeric(id)) {
            $.get(url+ '' +id, function(data) {

                // Jelezze, ha hiba történt
                if (data.response == 'error') {
                    alert(data.message);

                    // Elem torlese
                } else {
                    $('.list-item[data-id="'+ id +'"]').remove();
                }
            })
                .fail(function() {
                    alert('Nem sikerült törölni a fájlt.');
                });

            // Csatolt file torlese
        } else {
            $(id).parent().remove();
        }
    }
}

$('a.delete').click(function(e) {

    if (confirm('Biztosan törölni szeretnéd?')) {
        return true;
    }

    return false;
});

function previewImage(img, event) {
    event.preventDefault();
    var $img = $(img).children('img');
    $('.image-view img').attr('src', $img.attr('src'));
}

function showTab(tab) {
    $('.tab[data-target="'+tab+'"]').click();
}

function attachmentSelected(select) {

    var value      = select.value;
    var questionId = select.dataset.questionId;

    var urlInput     = document.querySelector('[name="answers['+ questionId +'][url]"]');
    var fileSelector = document.querySelector('[data-type="file-selector"][data-question-id="'+ questionId +'"]');

    if (value === 'url') {
        showElem(urlInput);
        hideElem(fileSelector);
    }

    if (value === 'file') {
        hideElem(urlInput);
        showElem(fileSelector);
    }
}

Dropzone.autoDiscover = false;
Dropzone.prototype.defaultOptions.dictDefaultMessage = "Drop files here to upload";
Dropzone.prototype.defaultOptions.dictFallbackMessage = "Your browser does not support drag'n'drop file uploads.";
Dropzone.prototype.defaultOptions.dictFallbackText = "Please use the fallback form below to upload your files like in the olden days.";
Dropzone.prototype.defaultOptions.dictFileTooBig = "File is too big (@{{filesize}}MiB). Max filesize: @{{maxFilesize}}MiB.";
Dropzone.prototype.defaultOptions.dictInvalidFileType = "You can't upload files of this type.";
Dropzone.prototype.defaultOptions.dictResponseError = "Server responded with @{{statusCode}} code.";
Dropzone.prototype.defaultOptions.dictCancelUpload = "Cancel upload";
Dropzone.prototype.defaultOptions.dictCancelUploadConfirmation = "Are you sure you want to cancel this upload?";
Dropzone.prototype.defaultOptions.dictRemoveFile = "Törlöm";
Dropzone.prototype.defaultOptions.dictMaxFilesExceeded = "You can not upload any more files.";

if (!window.AdvertsForm) {
    window.AdvertsForm = [];
}

$(function() {
    $("select.select2").select2({
    });

    $("select.combobox2").select2({
        tags: true
    });

    $("select.combobox-single").select2({
        tags: true,
        createTag: function (params) {
            return {
                id: params.term,
                text: params.term,
                newOption: true
            }
        },
        templateResult: function (data) {
            var $result = $("<span></span>");

            $result.text(data.text);

            if (data.newOption) {
                $result.append(" <em>(egyéb)</em>");
            }

            return $result;
        }
    });

    $("select.select-tags").select2({
        tags: true,
        tokenSeparators: [','],
        ajax: {
            url: "/adverts/tags",
            dataType: 'json',
            delay: 250,
            data: function (params) {
                return {
                    q: params.term, // search term
                    page: params.page
                };
            },
            processResults: function (data) {
                return {
                    results: data
                };
            },
            cache: true
        },
        escapeMarkup: function (markup) { return markup; }, // let our custom formatter work
        minimumInputLength: 1
    });

    $("#imagesDropzone").dropzone({
        url: window.AdvertsForm.imageUploadUrl,
        paramName: "image",

        accept: function(file, done) {
            done();
        },

        init: function() {
            this.on("thumbnail", function(file, dataUrl) {
                var highlightThisBtn = document.createElement('span');
                highlightThisBtn.innerHTML = "Kiemelem";
                highlightThisBtn.className = "image-highlight-btn";
                highlightThisBtn.setAttribute('data-path', file.name);
                highlightThisBtn.onclick = function() {
                    $('#default_image').val(this.getAttribute('data-path'));
                    $('.dz-preview').removeClass('selected');
                    $(this).closest('.dz-preview').addClass('selected');
                };

                file.previewTemplate.appendChild(highlightThisBtn);

                // Kep kiemelese
                if ($('#default_image').val().endsWith(file.name)) {
                    $(highlightThisBtn).closest('.dz-preview').addClass('selected');
                }
            });

            this.on("successmultiple", function(files, response) {
                if (!response['success']) {
                    alert('Fájl feltöltési hiba!');
                }

                window.AdvertsForm.addImage(response);
            });

            this.on("success", function(file, response) {
                if (!response['success']) {
                    return;
                }
            });

            this.on("removedfile", function(file) {
                window.AdvertsForm.removeImage(file);
            });

            var thisDropzone = this;

            // Meg mentetlen kepeket is toltse be ujra (mentes kozbeni hiba eseten visszakapjuk a mentetlen kepeket is)
            if (window.AdvertsForm.submittedButNotSavedImages) {

                $.each(window.AdvertsForm.submittedButNotSavedImages, function(key, value) {
                    var mock = { name: value, size: 0, url: window.AdvertsForm.uploadsPath + value };

                    this.files.push(mock);
                    this.emit('addedfile', mock);
                    this.createThumbnailFromUrl(mock, mock.url);
                    this.emit('complete', mock);

                    window.AdvertsForm.addImage({'name': value});
                }.bind(this));

            // Szerkesztes megnyitasakor, kepek lekerdezese
            } else {
                $.getJSON(window.AdvertsForm.getUploadedImagesUrl, function(data) {
                    $.each(data, function(key, value) {
                        var mock = { name: value.name, size: value.size, url: window.AdvertsForm.uploadsPath + value.name };

                        this.files.push(mock);
                        this.emit('addedfile', mock);
                        this.createThumbnailFromUrl(mock, mock.url);
                        this.emit('complete', mock);

                        window.AdvertsForm.addImage({'name': value.name});
                    }.bind(this));
                }.bind(this));
            }


        },

        uploadMultiple: true,
        parallelUploads: 1,
        maxFiles: 10,
        maxFilesize: 10, //mb
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        clickable: true
    });


    window.AdvertsForm.currentTemplateDropzoneInstances = [];

    // Kepfeltoltok initializalasa a reszletek nezeten
    window.AdvertsForm.currentTemplateDropzoneInstances = $('.template-wrapper .template_image').dropzone({
        init: function() {
            this.on("thumbnail", function(file, dataUrl) {

                // Feltoltott kep bemasolasa hatternek
                $(file['previewElement'])
                    .closest('.template_image')
                    .find('.dz-image')
                    .last()
                    .css('background-image', 'url('+dataUrl+')')
                    .html('');
            });

            this.on("removedfile", function (file) {
                var currentDropzoneIndex = $(this.element).data('index');
                $('#template_image_' + currentDropzoneIndex).val('');
            });

            this.on("success", function(file, response) {
                if (!response['success']) {
                    alert('Fájl feltöltési hiba!');
                }

                var currentDropzoneIndex = $(this.element).data('index');

                $('#template_image_' + currentDropzoneIndex).val(response['name']);
            });

            setTimeout(function() {
                if (!window.AdvertsForm.currentTemplateDropzoneInstances) {
                    return;
                }

                var currentDropzoneIndex = $(this.element).data('index');

                if (currentDropzoneIndex < 1) {
                    return;
                }

                // Mentett kepek visszatoltese szerkesztesnel
                if($('#template_image_' + currentDropzoneIndex).val().length > 0) {
                    var mockFileName = $('#template_image_' + currentDropzoneIndex).val();
                    var mock = { name: mockFileName, size: 100, url: window.AdvertsForm.uploadsPath + mockFileName };

                    this.files.push(mock);
                    this.emit('addedfile', mock);
                    this.createThumbnailFromUrl(mock, mock.url);
                    this.emit('complete', mock);
                }
            }.bind(this), 0);

        },

        thumbnailWidth: 600,
        thumbnailHeight: 600,

        url: window.AdvertsForm.imageUploadUrl,
        uploadMultiple: false,
        parallelUploads: 1,
        maxFiles: 1,
        maxFilesize: 10, //mb
        acceptedFiles: 'image/*',
        addRemoveLinks: true,
        clickable: true,
        paramName: "image"
    });

    if (window.AdvertsForm.currentTemplate) {
        $('.template-tile[data-target="template-0'+ window.AdvertsForm.currentTemplate +'"]').click();
    }
});

window.AdvertsForm.setSaveTypeAndSubmit = function(saveType) {
    $('#save_type').val(saveType);

    $('#advert-form').submit();
};

window.AdvertsForm.addContact = function(contact) {
    var tmpl = $.templates("#contact_select_template");
    var html = tmpl.render(contact);

    $('#contacts').append(html);
};

window.AdvertsForm.addPor = function(por) {
    var tmpl = $.templates("#por_select_template");
    var html = tmpl.render(por);

    $('#pors').append(html);
};

window.AdvertsForm.addImage = function(image) {
    var tmpl = $.templates("#image_template");
    var html = tmpl.render(image);

    $('#images').append(html);
};

window.AdvertsForm.savePor = function(caller) {
    $.post(window.AdvertsForm.savePorUrl, $.param($(caller).closest('form').serializeArray()), function(result) {
        window.AdvertsForm.addPor(result);
        $('.new-por-popup-wrapper').removeClass('active');
        $(caller).closest('form').find("input[type=text], textarea").val("");
    });
};

window.AdvertsForm.saveContact = function(caller) {
    $.post(window.AdvertsForm.saveContactUrl, $.param($(caller).closest('form').serializeArray()), function(result) {
        window.AdvertsForm.addContact(result);
        $('.new-contact-popup-wrapper').removeClass('active');
        $(caller).closest('form').find("input[type=text], textarea").val("");
    });
};

window.AdvertsForm.removeImage = function(image) {
    $('#images input[value="' + image.name + '"]').remove();
};

window.AdvertsForm.uncheckCurrentRecord = function(caller) {
    $(caller).closest('label').find('input').prop('checked', false);
};

window.AdvertsForm.onAvailabilityChange = function(caller) {
    $('#availability_at_container').hide();

    if (caller.value == 'on_request') {
        $('#availability_at_container').show();
    }
};

window.AdvertsForm.onPriceTypeChange = function(caller) {
    $('.price-inputs').hide();
    $('.price-inputs input').prop('disabled', true);
    $('#'+$(caller).find(':selected').val()).show().find('input').prop('disabled', false);
};

window.AdvertsForm.changeTemplate = function(templateIndex) {

    $('#template').val(templateIndex);

    $('#templateWrapper')
        .removeClass('template-1 template-2 template-3')
        .addClass('template-'+templateIndex);
};

window.AdvertsForm.addStaffLevel = function (staffLevel) {
    var tmpl = $.templates("#staff_level_select_template");
    var html = tmpl.render(staffLevel);

    $('#staff_levels').append(html);
};

window.AdvertsForm.addLocationAvailability = function (availability) {

    var tmpl = $.templates("#location_availability_select_template");
    var html = tmpl.render(availability);

    $('#location_availability').append(html);
};

window.AdvertsForm.addEquipmentAvailability = function (availability) {

    var tmpl = $.templates("#equipment_availability_select_template");
    var html = tmpl.render(availability);

    $('#equipment_availability').append(html);
};

window.AdvertsForm.changeInfrastructureType = function (type) {
    $('.infrastructure-form').hide();

    $('#infrastructure-' + type).show();

    $('#infrastructure-contact').show();
};

window.AdvertsForm.changeCounty = function (county) {
    $('#cities option').remove();
    var selectedCounties = [];

    $('#counties :selected').each(function () {
        selectedCounties.push($(this).val());
    });


    $('#cities').append($.map(window.AdvertsForm.countyCityAssociations, function (v, i) {
        //console.log(selectedCounties.indexOf(v.county));
        if (selectedCounties.indexOf(v.county) < 0) {
            return null;
        }

        var isSelected = window.AdvertsForm.selectedCities.indexOf(v.city) > -1;

        return $('<option>', {val: v.city, text: v.city, selected: isSelected});
    }));
};

$(function () {
    window.AdvertsForm.changeCounty();
});

window.AdvertsForm.changeCity = function (city) {
    window.AdvertsForm.selectedCities = [];

    $('#cities :selected').each(function () {
        window.AdvertsForm.selectedCities.push($(this).val());
    });

};

for (var initialStaffIndex in window.AdvertsForm.initialStaffLevels) {
    var staffLevel = window.AdvertsForm.initialStaffLevels[initialStaffIndex];

    window.AdvertsForm.addStaffLevel(staffLevel);
}

for (var initialLocationAvailabilityIndex in window.AdvertsForm.initialLocationAvailability) {
    var availability = window.AdvertsForm.initialLocationAvailability[initialLocationAvailabilityIndex];

    window.AdvertsForm.addLocationAvailability(availability);
}

for (var initialEquipmentAvailabilityIndex in window.AdvertsForm.initialEquipmentAvailability) {
    var availability = window.AdvertsForm.initialEquipmentAvailability[initialEquipmentAvailabilityIndex];

    window.AdvertsForm.addEquipmentAvailability(availability);
}

for(var initialContactIndex in window.AdvertsForm.initialContacts) {
    var contact = window.AdvertsForm.initialContacts[initialContactIndex];

    if (window.AdvertsForm.contactIds.indexOf(parseInt(contact.id)) > -1) {
        contact.selected = true;
    }

    window.AdvertsForm.addContact(contact);
}

for(var initialPorIndex in window.AdvertsForm.initialPors) {
    var por = window.AdvertsForm.initialPors[initialPorIndex];

    // por.id-t mindig szamkent kell kezelni, mert a porIds-ok is szamok
    if (window.AdvertsForm.porIds.indexOf(parseInt(por.id)) > -1) {
        por.selected = true;
    }

    window.AdvertsForm.addPor(por);
}


class Modal {

    constructor(modal, initListeners = true) {

        this.modal     = modal;
        this.cancel    = this.modal.querySelectorAll('[close]');
        this.modalBox  = this.modal.querySelector('.modal');
        this.flex      = this.modal.querySelector('.modal-flex');

        // A leszarmazott osztaly eventListenere hamarabb meghivodott,
        // mint hogy az init lefutott volna, igy hibat dobott.
        // TODO: ezt profibban megoldani
        if (initListeners) {
            this.eventListeners();
        }

        // Taroljuk el a modalt, hogy kesobb vissza lehessen keresni
        if (this.modal.dataset.name) {
            Modal.modals[this.modal.dataset.name] = this;
        }
    }

    eventListeners() {

        // CLOSE
        $(this.cancel).on('click', () => {
            this.close()
        });

        // Tartalomra kattintva ne zarodjon be a modal
        this.modalBox.addEventListener('click', (event) => {
            event.stopPropagation();
        });

        // Hatterre kattinva zarodjon be a modal
        this.flex.addEventListener('click', () => {
           this.close();
        });

        // CLOSE on ESC
        $(document).bind('keydown', (e) => {
            if (e.which == 27) {
                $(this.modal).removeClass('active');
            }
        });
    }

    open() {
        $(this.modal).addClass('active');
    }

    close() {
        $(this.modal).removeClass('active');
    }

}

// Statikus valtozot, csak igy lehet hozzadni egyenlore
Modal.modals = {};

// Infotar elem modal
class InfotarModal extends Modal {

    constructor(modal, reportModal) {
        super(modal, false);

        this.reportModal  = reportModal;
        this.content      = this.modal.querySelector('[content]');
        this.reportButton   = this.modal.querySelector('[report-button]');
        this.validateButton = this.modal.querySelector('[validate-button]');
        this.downloadButton = this.modal.querySelector('[download-button]');
        this.data         = null;

        this.eventListeners();
    }

    eventListeners() {
        super.eventListeners();

        // Infotar bejegyzes jelentese
        this.reportButton.addEventListener('click', (event) => {

            // Ha nincs bejelentkezve, akkor ne is tudjon jelenteni
            if ($(this.reportButton).hasClass('disabled')) {
                event.preventDefault();
                return;
            }

            this.close();

            // Report modal megnyitasa
            this.reportModal.render(this.data);
        });
    }

    render(data) {

        // Taroljuk el  a reportModalhoz
        this.data = data;

        let html = `<table>
                <tr>
                    <th>Dokumentum neve:</th>
                    <td>${data.title}</td>
                </tr>
                <tr>
                    <th>Program:</th>
                    <td>${data.program}</td>
                </tr>
                <tr>
                    <th>Típus:</th>
                    <td>${data.type}</td>
                </tr>`;

        if (data.theme) {
            html += `
                <tr>
                    <th>Téma:</th>
                    <td>${data.theme}</td>
                </tr>`;
        }

        if (data.theme) {
            html += `
                <tr>
                    <th>Célcsoport:</th>
                    <td>${data.target}</td>
                </tr>`;
        }

        html += `
                <tr>
                    <th>Keletkezés éve:</th>
                    <td>${data.publish_year}</td>
                </tr>
                <tr>
                    <th>Feltöltés dátuma:</th>
                    <td>${data.created_at}</td>
                </tr>`;

        if (data.social_enterprise) {
            html += `
                <tr>
                    <th>Társadalmi vállalkozás:</th>
                    <td>${data.social_enterprise}</td>
                </tr>`;
        }

        if (data.related_organization) {
            html += `
                <tr>
                    <th>Kapcsolódó szervezet neve:</th>
                    <td>${data.related_organization}</td>
                </tr>`;
        }

        if (data.location) {
            html += `
                <tr>
                    <th>Helyszín:</th>
                    <td>${data.location}</td>
                </tr>`;
        }

        if (data.category) {
            html += `
                <tr>
                    <th>Kategória:</th>
                    <td>${data.category}</td>
                </tr>`;
        }

        html += `</table>`;

        this.content.innerHTML = html;

        // Letoltes url firssitese
        this.downloadButton.href = data.url;

        // Validate url frissites
        this.validateButton.style.display = 'none';
        if (data.validate_url != '') {
            this.validateButton.style.display = '';
            this.validateButton.href = data.validate_url;
        }

        this.open();
    }

}

// Infotar jelentes modal
class InfotarReportModal extends Modal {

    constructor(modal) {
        super(modal, false);

        this.messageTextarea = this.modal.querySelector('[reporting-message]');
        this.idField         = this.modal.querySelector('[reporting-infotar-id]');
        this.submitButton    = this.modal.querySelector('[reporting-submit-button]');

        this.eventListeners();
    }

    eventListeners() {
        super.eventListeners();

        this.messageTextarea.addEventListener('keyup', () => {
           this.validate();
        });
    }

    validate() {

        // Minden kitoltve, kuldes gomb engedelyezese
        this.submitButton.disabled = this.messageTextarea.value == '';
    }

    render(data) {

        this.idField.value = data.id;
        this.open();
    }

}

// Infotar elem
class InfotarItem {

    constructor(item, modal) {
        this.item  = item;
        this.modal = modal;
        this.data  = JSON.parse(this.item.dataset.infotar);

        this.eventListeners();
    }

    eventListeners() {
        $(this.item).on('click', (event) => {
            event.preventDefault();

            this.modal.render(this.data);
        });
    }
}


(function() {
    var infotarElement = document.querySelector('[infotar-modal]');

    if (infotarElement) {
        var infotarReportModal = new InfotarReportModal(document.querySelector('[infotar-report-modal]'));
        var infotarModal       = new InfotarModal(infotarElement, infotarReportModal);

        $('[infotar-item]').each((index, item) => {
            new InfotarItem(item, infotarModal);
        });
    }
})();

$('[modal]').each(function() {
   new Modal(this);
});

// ONERTEKELES TEXTAREA COUNTER
